import { createSlice } from '@reduxjs/toolkit'
import makeCall from '../util/Api'
import CryptoJS from 'crypto-js'
import i18n from '../i18n/i18n'
import {
    BINGO_FLAGS,
    BINGO_MODES,
    BINGO_PHASES,
    BINGO_THEMES,
    SPOLIGHT_TYPE,
    generateCustomThemeVersionUrl,
    parseActivityFeedUser
} from '../util/Utils'
import { playBingoAudio, playLineAudio, playPopSound, playVictorySound } from '../util/Audio'

export const scoreboardSlice = createSlice({
    name: 'scoreboard',
    initialState: {
        broadcaster: {},
        bingo: {},
        linePeople: [],
        bingoPeople: [],
        lineWinners: [],
        bingoWinners: [],

        spotlightType: SPOLIGHT_TYPE.NUMBERS,
        startRandom: false,
        randomNumber: 0,

        wrongBroadcasterId: false,
        finishedLoading: false,

        customThemeShowed: null
    },
    reducers: {
        loadScoreboardStart: (state) => {
            state.finishedLoading = false
        },
        loadScoreboardSuccess: (state, action) => {
            let broadcaster = action.payload.broadcaster
            if (!broadcaster.broadcaster_id) return state   //Caso in cui il broadcaster_id non esiste (teoricamente impossibile dato che sennò uno streamer non potrebbe avere il link)

            i18n.locale = broadcaster.language

            let bingo
            const bingoPeople = []
            const linePeople = []
            const bingoWinners = []
            const lineWinners = []

            let spotlightType = SPOLIGHT_TYPE.NUMBERS

            //C'è un bingo attivo
            if (action.payload.bingo.broadcaster_id) {
                bingo = action.payload.bingo

                action.payload.activity_feed.forEach(e => {
                    const activityFeedUser = parseActivityFeedUser(e)
                    if (activityFeedUser.type === 'BINGO') {
                        bingoPeople.push(activityFeedUser)
                    } else if (activityFeedUser.type === 'LINE') {
                        linePeople.push(activityFeedUser)
                    } else if (activityFeedUser.type === 'WIN_BINGO') {
                        bingoWinners.push(activityFeedUser)
                    } else if (activityFeedUser.type === 'WIN_LINE') {
                        lineWinners.push(activityFeedUser)
                    }
                })

                if (bingoWinners.length > 0 && bingo.phase === BINGO_PHASES.COMPLETED) {
                    spotlightType = SPOLIGHT_TYPE.BINGO_WINNERS
                } else if (bingoPeople.length > 0) {
                    spotlightType = SPOLIGHT_TYPE.BINGO_PEOPLE
                } else if (linePeople.length > 0 && !bingo.line_winners_declared) {
                    spotlightType = SPOLIGHT_TYPE.LINE_PEOPLE
                }

                generateCustomThemeVersionUrl(bingo.custom_theme)
            }

            //se il bingo non esiste inserisco dei parametri di default che permettono di stampare il tabellone
            else {
                bingo = {
                    mode: BINGO_MODES.US_CLASSIC,
                    drawn_numbers: [],
                    theme: BINGO_THEMES.CLASSIC,
                    flag: BINGO_FLAGS.US,
                    numbers_in_scoreboard: 75,
                    rows: 5,
                    cols: 15
                }
            }

            return {
                ...state,
                broadcaster,
                bingo,
                linePeople,
                bingoPeople,
                lineWinners,
                bingoWinners,
                finishedLoading: true,
                spotlightType
            }
        },
        loadScoreboardError: (state) => {
            state.finishedLoading = true
        },
        setWrongBroadcasterId: (state) => {
            return {
                ...state,
                wrongBroadcasterId: true
            }
        },
        createBingoSuccess: (state, action) => {
            const bingo = action.payload
            generateCustomThemeVersionUrl(bingo.custom_theme)

            return {
                ...state,
                bingo,
                linePeople: [],
                bingoPeople: [],
                lineWinners: [],
                bingoWinners: [],
                spotlightType: SPOLIGHT_TYPE.NUMBERS,
                randomNumber: 0
            }
        },
        callLineSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state
            if (state.bingo.phase !== BINGO_PHASES.PLAYING) return state

            const user = action.payload

            const linePeople = [...state.linePeople]
            const found = linePeople.find(it => it.viewer_id === user.viewer_id)
            if (!found) {
                const activityFeedUser = parseActivityFeedUser(user)
                linePeople.push(activityFeedUser)
                playLineAudio(state.broadcaster.language)
            }

            return {
                ...state,
                linePeople,
                spotlightType: SPOLIGHT_TYPE.LINE_PEOPLE,
                startRandom: false,
                randomNumber: linePeople.length - 1
            }
        },
        callBingoSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state
            if (state.bingo.phase !== BINGO_PHASES.PLAYING) return state

            const user = action.payload

            const bingoPeople = [...state.bingoPeople]
            const found = bingoPeople.find(it => it.viewer_id === user.viewer_id)
            if (!found) {
                const activityFeedUser = parseActivityFeedUser(user)
                bingoPeople.push(activityFeedUser)
                playBingoAudio(state.broadcaster.language)
            }

            return {
                ...state,
                bingoPeople,
                spotlightType: SPOLIGHT_TYPE.BINGO_PEOPLE,
                startRandom: false,
                randomNumber: bingoPeople.length - 1
            }
        },
        drawnNumbersSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state
            if (state.bingo.phase !== BINGO_PHASES.PLAYING) return state

            if (state.broadcaster.enabled_sounds.numbers) {
                playPopSound()
            }

            const drawn_numbers = action.payload
            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    drawn_numbers
                },
                spotlightType: SPOLIGHT_TYPE.NUMBERS,
                startRandom: false,
                randomNumber: drawn_numbers[drawn_numbers.length - 1]
            }
        },
        startRandomNumberSuccess: (state) => {
            if (!state.bingo.creation_date) return state
            if (state.bingo.phase !== BINGO_PHASES.PLAYING ||
                (state.spotlightType !== SPOLIGHT_TYPE.NUMBERS && state.spotlightType !== SPOLIGHT_TYPE.LINE_WINNERS)) return state

            let spotlightType = state.spotlightType
            if (spotlightType === SPOLIGHT_TYPE.LINE_WINNERS) {
                spotlightType = SPOLIGHT_TYPE.NUMBERS
            }

            return {
                ...state,
                startRandom: true,
                spotlightType
            }
        },
        startRandomLineWinnerSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state

            return {
                ...state,
                linePeople: action.payload,
                startRandom: action.payload.length > 1,
                spotlightType: SPOLIGHT_TYPE.LINE_PEOPLE
            }
        },
        startRandomBingoWinnerSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state

            return {
                ...state,
                bingoPeople: action.payload,
                startRandom: action.payload.length > 1,
                spotlightType: SPOLIGHT_TYPE.BINGO_PEOPLE
            }
        },
        declareLineWinnersSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state

            if (state.broadcaster.enabled_sounds.winners) {
                playVictorySound()
            }

            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    line_winners_declared: true
                },
                lineWinners: action.payload,
                spotlightType: SPOLIGHT_TYPE.LINE_WINNERS,
                startRandom: false,
                randomNumber: 0
            }
        },
        declareBingoWinnersSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state

            if (state.broadcaster.enabled_sounds.winners) {
                playVictorySound()
            }

            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    phase: BINGO_PHASES.COMPLETED
                },
                bingoWinners: action.payload,
                spotlightType: SPOLIGHT_TYPE.BINGO_WINNERS,
                startRandom: false,
                randomNumber: 0
            }
        },
        cancelRandomSuccess: (state) => {
            return {
                ...state,
                startRandom: false,
                randomNumber: 0
            }
        },
        startBingoSuccess: (state) => {
            if (!state.bingo.creation_date) return state
            if (state.bingo.phase !== BINGO_PHASES.WAITING) return state
            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    phase: BINGO_PHASES.PLAYING
                }
            }
        },
        updateTitleSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state
            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    title: action.payload
                }
            }
        },
        updateStartingDateSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state
            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    starting_date: action.payload
                }
            }
        },
        endBingoSuccess: (state) => {
            if (!state.bingo.creation_date) return state
            return {
                ...state,
                bingo: {
                    ...state.bingo,
                    drawn_numbers: [],
                    phase: BINGO_PHASES.OVER
                },
                lineWinners: [],
                bingoWinners: [],
                linePeople: [],
                bingoPeople: [],
                spotlightType: SPOLIGHT_TYPE.NUMBERS,
                startRandom: false,
                randomNumber: 0
            }
        },
        updateLanguageSuccess: (state, action) => {
            const language = action.payload
            i18n.locale = language
            return {
                ...state,
                broadcaster: {
                    ...state.broadcaster,
                    language
                }
            }
        },
        updateEnabledSoundsSuccess: (state, action) => {
            return {
                ...state,
                broadcaster: {
                    ...state.broadcaster,
                    enabled_sounds: {
                        ...state.broadcaster.enabled_sounds,
                        [action.payload.type]: action.payload.enabled
                    }
                }
            }
        },
        retryActivityFeedSuccess: (state, action) => {
            if (!state.bingo.creation_date) return state

            const user = action.payload

            const bingoPeople = state.bingoPeople.map(it => {
                if (it.viewer_id === user.viewer_id) {
                    return {
                        ...it,
                        display_name: user.display_name,
                        profile_image_url: user.profile_image_url
                    }
                }
                return it
            })
            const linePeople = state.linePeople.map(it => {
                if (it.viewer_id === user.viewer_id) {
                    return {
                        ...it,
                        display_name: user.display_name,
                        profile_image_url: user.profile_image_url
                    }
                }
                return it
            })
            return {
                ...state,
                bingoPeople,
                linePeople
            }
        },
        updateRandomSpotlightNumber: (state) => {
            const spotlightType = state.spotlightType
            const startRandom = state.startRandom
            let randomNumber = state.randomNumber

            if (spotlightType === SPOLIGHT_TYPE.BINGO_WINNERS || spotlightType === SPOLIGHT_TYPE.LINE_WINNERS ||
                (!startRandom && (spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE || spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE))) {
                const users = spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE ? state.linePeople :
                    spotlightType === SPOLIGHT_TYPE.LINE_WINNERS ? state.lineWinners :
                        spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE ? state.bingoPeople :
                            state.bingoWinners
                randomNumber = randomNumber >= users.length - 1 ? 0 : randomNumber + 1
            } else if (spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE || spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE) {
                //startRandom è true
                const users = spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE ? state.linePeople : state.bingoPeople
                let bingoPeopleIndices = Array.from(Array(users.length).keys())
                bingoPeopleIndices.splice(randomNumber, 1)
                const randomIndex = Math.floor(Math.random() * bingoPeopleIndices.length)
                randomNumber = bingoPeopleIndices[randomIndex]
                if (!randomNumber) randomNumber = 0
            } else {
                //spotlightType === SPOLIGHT_TYPE.NUMBERS
                if (startRandom) {
                    const bingo = state.bingo
                    let remainingNumbers
                    if (bingo.drawn_numbers.length === bingo.numbers_in_scoreboard) {
                        return state
                    } else if (bingo.drawn_numbers.length + 1 === bingo.numbers_in_scoreboard) {
                        remainingNumbers = Array.from({ length: bingo.numbers_in_scoreboard }, (_, i) => i + 1).filter(number => !bingo.drawn_numbers.includes(number))
                    } else {
                        remainingNumbers = Array.from({ length: bingo.numbers_in_scoreboard }, (_, i) => i + 1).filter(number => !bingo.drawn_numbers.includes(number) && number !== randomNumber)
                    }
                    const randomIndex = Math.floor(Math.random() * remainingNumbers.length)
                    randomNumber = remainingNumbers[randomIndex]
                }
            }

            return {
                ...state,
                randomNumber
            }
        },
        showCustomThemeSuccess: (state, action) => {
            return {
                ...state,
                customThemeShowed: action.payload
            }
        },
        hideCustomThemeSuccess: (state) => {
            return {
                ...state,
                customThemeShowed: null
            }
        }
    }
})

export const {
    loadScoreboardStart,
    loadScoreboardSuccess,
    loadScoreboardError,
    setWrongBroadcasterId,
    createBingoSuccess,
    drawnNumbersSuccess,
    callBingoSuccess,
    callLineSuccess,
    startRandomNumberSuccess,
    startRandomLineWinnerSuccess,
    startRandomBingoWinnerSuccess,
    declareLineWinnersSuccess,
    declareBingoWinnersSuccess,
    cancelRandomSuccess,
    startBingoSuccess,
    updateTitleSuccess,
    updateStartingDateSuccess,
    endBingoSuccess,
    updateLanguageSuccess,
    updateEnabledSoundsSuccess,
    retryActivityFeedSuccess,
    updateRandomSpotlightNumber,
    showCustomThemeSuccess,
    hideCustomThemeSuccess
} = scoreboardSlice.actions

export default scoreboardSlice.reducer

export const loadScoreboard = (encrypted_broadcaster_id) => (dispatch) => {
    const reb64 = CryptoJS.enc.Hex.parse(encrypted_broadcaster_id)
    const bytes = reb64.toString(CryptoJS.enc.Base64)
    const decrypt = CryptoJS.AES.decrypt(bytes, 'livebingo')
    let broadcaster_id
    try {
        broadcaster_id = decrypt.toString(CryptoJS.enc.Utf8)
    } catch {
        dispatch(setWrongBroadcasterId())
        return
    }
    if (!broadcaster_id) {
        dispatch(setWrongBroadcasterId())
        return
    }

    const json = JSON.stringify({
        broadcaster_id
    })
    dispatch(loadScoreboardStart())
    makeCall('POST', 'v1/getwebsitebingo', json).then(response => {
        dispatch(loadScoreboardSuccess(response))
    }).catch(err => {
        dispatch(loadScoreboardError(err))
    })
}

export const loopSpotlight = () => (dispatch, getState) => {
    const startRandom = getState().scoreboard.startRandom
    const intervalTime = startRandom ? 250 : 2000

    const intervalId = setInterval(() => {
        dispatch(updateRandomSpotlightNumber())
    }, intervalTime)
    return intervalId
}

export const showCustomTheme = (custom_theme) => (dispatch) => {
    generateCustomThemeVersionUrl(custom_theme)
    if (custom_theme.flag === BINGO_FLAGS.US) {
        custom_theme.rows = 5
        custom_theme.cols = 15
    } else {
        custom_theme.rows = 9
        custom_theme.cols = 10
    }

    dispatch(hideCustomThemeSuccess())
    dispatch(showCustomThemeSuccess(custom_theme))
    const timeoutId = setTimeout(() => {
        dispatch(hideCustomThemeSuccess())
    }, 30000)
    return timeoutId
}


